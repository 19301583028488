.columns-85 {
  width: 20%;
  height: 85%;
  float: left;
  transition: all 0.5s linear;
  z-index: 50;
}

.columns-100 {
  width: 20%;
  height: 100%;
  float: left;
  position: absolute;
  transition: all 0.5s linear;
  z-index: 5;
}

.columns:hover {
  z-index: 10;
  transition-delay: 0.5s;
  width: 40%;
  overflow: visible;
}

.col2:hover {
  background-color: #217c9d;
  left: 15%;
}

.col3:hover {
  left: 35%;
}

.col4:hover {
  left: 55%;
}

.col5:hover {
  left: 65%;
}
